import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination";
import Loader from "../loader";
import "../../index.css";

const FacturasDash = () => {
  const [clientesList, setClientesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sucursalesList, setSucursalesList] = useState([]);
  const userType = localStorage.getItem("userType");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [totalClientes, setTotalClientes] = useState(0);
  const [loading, setLoading] = useState(false);

  const [currentFacturas, setCurrentFacturas] = useState(0);

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  
  // Load initial data
  useEffect(() => {
    const fetchSucursales = async () => {
      try {
        const response = await axios.get("/sucursales");
        const fetchedSucursales = response.data.data;
        setSucursalesList(fetchedSucursales);
      } catch (error) {
        console.error("Error al obtener sucursales:", error);
      }
    };

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("/facturacion/obtenerFacturas");
        const fetchedClientes = response.data.results;
        setLoading(false);
        setClientesList(fetchedClientes);

        // Calcula las páginas totales basadas en el número de clientes inicial
        const totalPages = Math.ceil(fetchedClientes.length / clientesPerPage);
        setTotalClientes(totalPages);

        // Filtra los clientes iniciales a mostrar
        setCurrentFacturas(fetchedClientes.slice(0, clientesPerPage));
      } catch (error) {
        console.error("Error fetching clients", error);
      }
    };

    fetchData();
    fetchSucursales();
    

  }, [userType]);



  // Dentro de la función handleSearch
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    // Siempre comienza desde la primera página al buscar
    setCurrentPage(1);
    setCurrentFacturas(filterClientes(1, searchTerm));
  };

  // Función para filtrar clientes por nombre de manera flexible
  const filterClientes = (page, searchTermR) => {
    setSearchTerm(searchTermR);
    let registrosIni = (page - 1) * clientesPerPage;

    let filtered = clientesList.filter((cliente) =>
      cliente.nombreCompleto.toLowerCase().includes(searchTermR.toLowerCase())
    );

    const totalPages = Math.ceil(filtered.length / clientesPerPage);
    setTotalClientes(totalPages);

    return filtered.slice(registrosIni, registrosIni + clientesPerPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentFacturas(filterClientes(page, searchTerm));
  };

  const descargarFactura = (base64, nombre, fecha) => {
    // Crear un enlace temporal
    const enlace = document.createElement('a');
  
    // Formatear la fecha en un formato legible (opcional)
    const fechaFormateada = new Date(fecha.split('T')[0]); // YYYY-MM-DD
  
    // Concatenar el nombre y la fecha para el nombre del archivo
    const nombreArchivo = `${nombre}_${fechaFormateada}.pdf`;
  
    // Decodificar el base64 y convertirlo en un blob
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
  
    // Crear una URL para el blob y asignársela al enlace
    const url = URL.createObjectURL(blob);
    enlace.href = url;
    enlace.download = nombreArchivo;
  
    // Hacer clic en el enlace para iniciar la descarga
    document.body.appendChild(enlace);
    enlace.click();
  
    // Limpiar y liberar recursos
    document.body.removeChild(enlace);
    URL.revokeObjectURL(url);
  };

  const reenviarFactura = async (base64, nombreCliente, fecha, xml) => {
    // Muestra una alerta para pedir el correo electrónico
    const { value: email } = await Swal.fire({
      title: 'Ingrese el correo electrónico',
      input: 'email',
      inputLabel: 'Correo electrónico para reenviar la factura',
      inputPlaceholder: 'Ingrese el correo electrónico',
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      inputValidator: (value) => {
        // Validación de correo electrónico
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex para validar correo electrónico
        if (!value) {
          return '¡Necesita ingresar un correo electrónico!';
        } else if (!emailRegex.test(value)) {
          return '¡Ingrese un correo electrónico válido!';
        }
      }
    });
  
    // Si el usuario ingresa un correo
    if (email) {
      setLoading(true);
      try {
        const response = await axios.post('/facturacion/reenvio-factura', {
          base64,
          nombreCliente,
          fecha,
          email,
          xml
        });
        
        if (response.data.success) {
          setLoading(false);
          Swal.fire('Éxito', 'Factura reenviada correctamente!', 'success');
        } else {
          setLoading(false);
          Swal.fire('Error', 'Error al reenviar la factura.', 'error');
        }
      } catch (error) {
        setLoading(false);
        console.error("Error al reenviar la factura:", error);
        Swal.fire('Error', 'Error en el proceso de reenviar la factura.', 'error');
      }
    }
  };

  const abrirFacturaEnNuevaPestana = (base64, nombre, fecha) => {
    // Formatear la fecha en un formato legible (opcional)
    const fechaFormateada = new Date(fecha.split('T')[0]); // YYYY-MM-DD
    
    // Concatenar el nombre y la fecha para el nombre del archivo
    const nombreArchivo = `${nombre}_${fechaFormateada}.pdf`;
    
    // Decodificar el base64 y convertirlo en un blob
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    
    // Crear una URL para el blob
    const url = URL.createObjectURL(blob);
  
    // Abrir el PDF en una nueva pestaña
    window.open(url, '_blank');
  
    // Limpiar y liberar recursos después de un breve tiempo
    setTimeout(() => {
      URL.revokeObjectURL(url); // Liberar el objeto URL después de usarlo
    }, 100);
  };
  
  const cancelarFactura = (folioFiscal, idFactura, selloCFDI) => {
    const idSucursal = localStorage.getItem("branchId");
    // Preguntar al usuario si está seguro de cancelar la factura
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez cancelada, no podrá revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: 'Sí, cancelar factura!',
      // Agregar contenido HTML para el select
      input: 'select',
      inputOptions: {
        '01': '01 - Comprobante emitido con errores con relación',
        '02': '02 - Comprobante emitido con errores sin relación',
        '03': '03 - No se llevó a cabo la operación',
        '04': '04 - Operación nominativa relacionada en una factura global',
      },
      inputPlaceholder: 'Seleccione un motivo',
      inputValidator: (value) => {
        return !value && '¡Debes seleccionar un motivo!';
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // Guardar el motivo seleccionado
        const motivoSeleccionado = result.value;

        // Realizar la llamada al endpoint para cancelar la factura
        try {
          const response = await axios.post('/facturacion/cancelar', {
            folioFiscal,
            idSucursal,
            motivo: motivoSeleccionado,
            idFactura,
            selloCFDI
          });

          if (response.data.codigo !== "000") {
            Swal.fire({
              title: '¡Éxito!',
              text: 'La factura ha sido cancelada correctamente.',
              icon: 'success',
              willClose: () => {
                window.location.reload();
              },
              allowOutsideClick: false
            });
            setLoading(false);
          } else if (response.data.codigo === "000" && response.data.estado.includes("No encontrado")) {
            Swal.fire({
              title: response.data.codigoEstatus,
              text: response.data.mensaje,
              icon: 'info',
              willClose: () => {
                window.location.reload();
              },
              allowOutsideClick: false
            });
            setLoading(false);
          } else {
            Swal.fire({
              title: response.data.codigoEstatus,
              text: response.data.mensaje,
              icon: 'info',
              willClose: () => {
                window.location.reload();
              },
              allowOutsideClick: false
            });
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          // Manejar errores
          console.error('Error al cancelar la factura:', error);
          Swal.fire({
            title: 'Error!',
            text: 'No se pudo cancelar la factura. Intente nuevamente.',
            icon: 'error',
          });
        }
      }
    });
  };

  return (
    <div>
      <Header />
      <Loader isLoading={loading} />
      <div style={{ margin: "0 2%" }} className="formCliente">
        <h3 className="mt-5">Lista de Facturas</h3>
        <div className="row mb-3" style={{ width: "1000px" }}>
          <div className="col-md-4">
            <span>Buscar por Nombre:</span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingresa el nombre"
              value={searchTerm}
              onChange={handleSearch}
            />
            {searchTerm !== "" && (
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  window.location.reload();
                }}
                style={{ marginTop: "8px" }}
              >
                <i className="bi bi-x-lg">Borrar búsqueda</i>
              </button>
            )}
          </div>
        </div>
        {currentFacturas.length > 0 ? (
          <>
            <table
              className="table table-dark"
              style={{ borderRadius: "20px" }}
            >
              <thead>
                <tr>
                  <th style={{ borderTopLeftRadius: "20px" }}>No. Factura</th>
                  <th>Fecha de Emisión</th>
                  <th>
                    Paciente
                  </th>
                  <th>
                    Sucursal
                  </th>
                  <th>Teléfono Paciente</th>
                  <th>no. Certificado</th>
                  <th>Folio Fiscal</th>
                  <th>Estatus</th>
                  <th style={{ borderTopRightRadius: "20px" }}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {currentFacturas.map((factura) => (
                  <tr key={factura.idFactura} className="tablaPedidos">
                    <td>{factura.idFactura}</td>
                    <td>
                      {factura.CreateDate
                        ? new Date(factura.CreateDate.split("T")[0]).toLocaleDateString()
                        : "Sin Fecha"}
                    </td>
                    <td>{factura.nombreCompleto}</td>
                    <td
                      style={{
                        maxWidth: "350px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={
                        sucursalesList.find((suc) => suc.id === factura.idSucursal)?.direccion || "N/A"
                      }
                    >
                      {sucursalesList.find((suc) => suc.id === factura.idSucursal)?.direccion || "N/A"}
                    </td>
                    <td>{factura.telefono ? factura.telefono : "Sin teléfono registrado"}</td>
                    <td>{factura.noCertificado}</td>
                    <td>{factura.folioFiscal ? factura.folioFiscal : "Sin folio fiscal"}</td>
                    <td>{factura.estatus ? factura.estatus : "Vigente"}</td>
                    <td>
                      <div  style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '5px' }}>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => descargarFactura(factura.base64, factura.nombreCompleto, new Date(factura.CreateDate.split("T")[0]).toLocaleDateString())}
                      >
                        Descargar
                      </button>

                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={() => reenviarFactura(factura.base64, factura.nombreCompleto, new Date(factura.CreateDate.split("T")[0]).toLocaleDateString(), factura.cfdiXml)}
                      >
                        Reenviar
                      </button>

                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => abrirFacturaEnNuevaPestana(factura.base64, factura.nombreCompleto, new Date(factura.CreateDate.split("T")[0]).toLocaleDateString())}
                        style={{ marginTop: "5px" }}
                      >
                        Ver
                      </button>

                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => cancelarFactura(factura.folioFiscal, factura.idFactura, factura.selloCFDI)}
                        style={{ marginTop: "5px" }}
                      >
                        Cancelar
                      </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalPages={totalClientes}
              itemsPerPage={10}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <h4 style={{ textAlign: "center", color: "lightgray" }}>
            No hay facturas
          </h4>
        )}
      </div>
    </div>
  );
};

export default FacturasDash;
