import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Header from "../Header";
import "../../index.css";
import Swal from "sweetalert2";

// Función para obtener la fecha actual en México
const getMexicoCurrentDate = () => {
  const mexicoTimezoneOffset = -6; // UTC-6 para horario estándar de México
  const now = new Date();
  const mexicoDate = new Date(
    now.setHours(now.getHours() + mexicoTimezoneOffset)
  );
  return mexicoDate.toISOString().split("T")[0]; // Formato 'YYYY-MM-DD'
};

const CajaClosure = () => {
  const [date, setDate] = useState(getMexicoCurrentDate());
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const idUser = localStorage.getItem("idUser");
  const name = localStorage.getItem("name");
  const lastName = localStorage.getItem("lastName");
  const secondLastName = localStorage.getItem("secondLastName");
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!idUser) {
        console.error("Error: idUser no está disponible en localStorage");
        return;
      }
      try {
        axios
          .get(`/cortesCaja/comprobacionDiaria/${idUser}?date=${date}`)
          .then((response) => {
            if (response.data.data[0]) {
              if (response.data.data[0].fechaCorte.includes(date)) {
                Swal.fire({
                  title: "¡Ya has registrado tu corte de hoy!",
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Regresar",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = "/dashboard";
                  }
                });
              }
            }
          });
      } catch (error) {
        console.error("Error fetching comprobation:", error);
      }

      try {
        let response = {};
        if (userType === "1") {
          // Consulta con el idUser y la fecha
          response = await axios.get(
            `/cortesCaja/sucursal/${idUser}?date=${date}`
          );

          if (response.data.sucursalAsignada === false) {
            Swal.fire({
              title: "¡No tiene sucursal asignada como encargado!",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Regresar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/dashboard";
              }
            });
          }
        } else {
          response = await axios.get(`/cortesCaja/${idUser}?date=${date}`);
        }
        
        const transactionsData = response.data.data || [];
        setTransactions(transactionsData);

        // Calcular el total general sumando todos los importes
        const totalAmount = transactionsData.reduce(
          (sum, transaction) => sum + transaction.importe,
          0
        );
        setTotal(totalAmount);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [date, idUser]);

  // Función para filtrar transacciones por tipo de pago y calcular el total
  const filterTransactionsByType = (formaPago) => {
    return transactions.filter(
      (transaction) => transaction.formaPago === formaPago
    );
  };

  const calculateTotalByType = (formaPago) => {
    return filterTransactionsByType(formaPago).reduce(
      (sum, transaction) => sum + transaction.importe,
      0
    );
  };

  const efectivoTransactions = filterTransactionsByType("Efectivo");
  const creditoTransactions = filterTransactionsByType("Credito");
  const debitoTransactions = filterTransactionsByType("Debito");
  const transferenciaTransactions = filterTransactionsByType("Transferencia");

  const totalEfectivo = calculateTotalByType("Efectivo");
  const totalCredito = calculateTotalByType("Credito");
  const totalDebito = calculateTotalByType("Debito");
  const totalTransferencia = calculateTotalByType("Transferencia");

  const sendClosure = () => {
    Swal.fire({
      title: "¿Confirmar el corte de caja?",
      text: "Confirma el corte si has validado los totales correspondientes a tu caja con certeza.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d00",
      confirmButtonText: "Confirmar corte",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const nombreCreador = `${name} ${lastName} ${secondLastName}`;
        const corteCajaData = {
          idCreador: idUser,
          nombreCreador: nombreCreador,
          idSucursal: localStorage.getItem("branchId"),
          totalEfectivo: totalEfectivo.toFixed(2),
          totalCredito: totalCredito.toFixed(2),
          totalDebito: totalDebito.toFixed(2),
          totalTransferencia: totalTransferencia.toFixed(2),
          totalGeneral: total.toFixed(2),
          fechaCorte: date,
          tipoUsuarioCreador: localStorage.getItem("userType"),
        };
        axios.post("/cortesCaja/crear", corteCajaData).then((response) => {
          if (response.data) {
            Swal.fire({
              title: "¡Corte registrado exitósamente!",
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Regresar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/dashboard";
              }
            });
          }
        });
      }
    });
  };

  return (
    <div>
      <Header />
      <div
        style={{
          justifyContent: "center",
          marginTop: '60px',
          paddingLeft: '20px'
        }}
      >
        <p style={{ fontSize: "28px", fontWeight: "700" }}>
          {userType==="1"?(
            "Corte de Caja Sucursal"
          ):("Corte de Caja Individual")} <br />
          <span style={{ fontWeight: "500", fontSize: '22px' }}>
            {name} {lastName}
          </span>{" "}
        </p>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          disabled={true}
          className="form-control"
          style={{
            width: "fit-content",
            textAlign: "center",
            fontWeight: "700",
          }}
        />

        {/* Contenedor para las tablas en fila */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: '1fr 1fr',
            gap: "20px",
            marginTop: "30px",
          }}
        >
          {/* Tabla para efectivo */}
          <div>
            <h4>
              <b>Transacciones en Efectivo</b>
            </h4>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <table
                className="table table-secondary"
                style={{ margin: "10px 0", width: "550px" }}
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Monto</th>
                    <th scope="col">Tipo de Pago</th>
                    <th scope="col">Facturado</th>
                    {userType === "1" ? (
                      <th scope="col">Persona en Caja</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {efectivoTransactions.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.id}</td>
                      <td>${transaction.importe.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>
                        {transaction.tipoPago === "total"
                          ? "Total"
                          : transaction.tipoPago}
                      </td>
                      <td>
                        {transaction.facturacion === "True" ? "Si" : "No"}
                      </td>
                      {userType === "1" ? (
                        <td>{transaction.nombreCreador}</td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h5>
              Total en Efectivo: <b>${totalEfectivo.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
            </h5>
          </div>

          {/* Tabla para crédito */}
          <div>
            <h4>
              <b>Transacciones con Crédito</b>
            </h4>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <table
                className="table table-secondary"
                style={{ margin: "10px 0", width: "550px" }}
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Monto</th>
                    <th scope="col">Tipo de Pago</th>
                    <th scope="col">Facturado</th>
                    {userType === "1" ? (
                      <th scope="col">Persona en Caja</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {creditoTransactions.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.id}</td>
                      <td>${transaction.importe.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>
                        {transaction.tipoPago === "total"
                          ? "Total"
                          : transaction.tipoPago}
                      </td>
                      <td>
                        {transaction.facturacion === "True" ? "Si" : "No"}
                      </td>
                      {userType === "1" ? (
                        <td>{transaction.nombreCreador}</td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h5>
              Total en Crédito: <b>${totalCredito.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
            </h5>
          </div>

          {/* Tabla para débito */}
          <div>
            <h4>
              <b>Transacciones con Débito</b>
            </h4>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <table
                className="table table-secondary"
                style={{ margin: "10px 0", width: "550px" }}
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Monto</th>
                    <th scope="col">Tipo de Pago</th>
                    <th scope="col">Facturado</th>
                    {userType === "1" ? (
                      <th scope="col">Persona en Caja</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {debitoTransactions.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.id}</td>
                      <td>${transaction.importe.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>
                        {transaction.tipoPago === "total"
                          ? "Total"
                          : transaction.tipoPago}
                      </td>
                      <td>
                        {transaction.facturacion === "True" ? "Si" : "No"}
                      </td>
                      {userType === "1" ? (
                        <td>{transaction.nombreCreador}</td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h5>
              Total en Débito: <b>${totalDebito.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
            </h5>
          </div>

          
          {/* Tabla para Transferencia */}
          <div style={{marginTop: '35px'}}>
            <h4>
              <b>Transacciones con Transferencia</b>
            </h4>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <table
                className="table table-secondary"
                style={{ margin: "10px 0", width: "550px" }}
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Monto</th>
                    <th scope="col">Tipo de Pago</th>
                    <th scope="col">Facturado</th>
                    {userType === "1" ? (
                      <th scope="col">Persona en Caja</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {transferenciaTransactions.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.id}</td>
                      <td>${transaction.importe.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>
                        {transaction.tipoPago === "total"
                          ? "Total"
                          : transaction.tipoPago}
                      </td>
                      <td>
                        {transaction.facturacion === "True" ? "Si" : "No"}
                      </td>
                      {userType === "1" ? (
                        <td>{transaction.nombreCreador}</td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h5>
              Total en Transferencia: <b>${totalTransferencia.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
            </h5>
          </div>
        </div>

        

        {/* Total general */}
        <h3>
          Total del Día: <b>${total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
        </h3>
        <button
          className="btn btn-success"
          style={{ margin: "50px auto", width: "fit-content" }}
          onClick={sendClosure}
        >
          Declarar corte
        </button>
      </div>
    </div>
  );
};

export default CajaClosure;
