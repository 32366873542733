import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import axios from "./api/axiosConfig";
import Login from "./components/Login";
import UsersAdmin from "./components/Admin/Users";
import SucursalesAdmin from "./components/Admin/Sucursales";
import Clientes from "./components/Clientes";
import Default from "./components/Default";
import TodasCitas from "./components/Citas/TodasCitas";
import CrearPedido from "./components/Pedidos/CrearPedido";
import TodosPedidos from "./components/Pedidos/TodosPedidos";
import PedidoVista from "./components/Pedidos/PedidoVista";
import PedidoEstatusUpdate from "./components/Pedidos/PedidoEstatusUpdate";
import PedidoDetalle from "./components/Pedidos/DetallesPedido";
import PedidoDescripcion from "./components/Pedidos/DescripcionPedido";
import VisitaCrear from "./components/Visitas/CrearVisita";
import VisitaVista from "./components/Visitas/VisitaVista";
import HistoriaClinica from "./components/Visitas/HistoriaClinica";
import VisitasHistorial from "./components/Visitas/Historial";
import UltimaVisitaHistorial from "./components/Visitas/UltimaVisita";
import HistoriaClinicaView from "./components/PDF/HistoriaClinicaView";
import NotaVentaView from "./components/PDF/NotaVentaView";
import CrearFactura from "./components/Facturacion/CrearFactura";
import CrearFacturaGeneral from "./components/Facturacion/CrearFacturaGeneral";
import ValidarCitaView from './components/Citas/validarCita';
import CajaClosure from './components/CortesCaja/CajaClosure';
import PanelCortesCaja from './components/CortesCaja/DashboardCortesCaja';
import PedidoTickets from "./components/Pedidos/ImpresionTickets";
import PedidoActualizar from "./components/Pedidos/EditarPedido";
import FacturasDashboard from "./components/Facturacion/FacturasDash";
import PoliticaPrivacidad from "./components/Politicaprivacidad";
import WhatsappPromociones from "./components/Admin/MessageTemplateEditor";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const userType = localStorage.getItem("userType");
  let pedidoEstatus = "";
  const idPedidoCurrent = sessionStorage.getItem("idPedidoCurrent");
  const pedidoIdItem = sessionStorage.getItem("pedidoEstatus");

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get("/validateTk", {
            headers: {
              Authorization: token,
            },
          });
          if (response.data.success) {
            setAuthenticated(true);
          } else {
            localStorage.removeItem("token");
          }
        } catch (error) {
          console.error("Error al verificar autenticación:", error);
          localStorage.removeItem("token");
        }
      }
      setLoading(false);
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  const path = window.location.pathname;
  pedidoEstatus = path.split("=")[1];

  if (pedidoEstatus) {
    sessionStorage.setItem("pedidoEstatus", pedidoEstatus);
  }

  if (pedidoEstatus && !authenticated) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<PedidoVista />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/login"
            element={authenticated ? <Navigate to="/dashboard" /> : <Login />}
          />

          <Route path="/pedidoEstatus" element={<PedidoVista />} />
          <Route path="/politicaPrivacidad" element={<PoliticaPrivacidad />} />
          <Route path="/Default" element={<Default />} />
          <Route path="/visita/ultimaVisita" element={<VisitaVista />} />
          <Route
            path="/historiaClinica/PDFGenerate"
            element={<HistoriaClinicaView />}
          />
          <Route
            path="/pedidos/GenerarNotaVentaPDF"
            element={<NotaVentaView />}
          />
          <Route
            path="/citas/validarCita"
            element={<ValidarCitaView />}
          />
          {authenticated && !idPedidoCurrent && !pedidoIdItem ? (
            userType === "0" ? (
              <>
                <Route path="/dashboard" element={<UsersAdmin />} />
                <Route
                  path="/dashboard/sucursales"
                  element={<SucursalesAdmin />}
                />
                <Route path="/dashboard/clientes" element={<Clientes />} />
                <Route path="/dashboard/citas" element={<TodasCitas />} />
                <Route
                  path="/dashboard/crearPedido"
                  element={<CrearPedido />}
                />
                <Route
                  path="/dashboard/editarPedido"
                  element={<PedidoActualizar />}
                />
                <Route path="/dashboard/pedidos" element={<TodosPedidos />} />
                <Route path="/pedido/detalles" element={<PedidoDetalle />} />
                <Route path="/pedido/descripcion" element={<PedidoDescripcion />} />


                <Route
                  path="/historial/nuevaVisita"
                  element={<VisitaCrear />}
                />
                <Route
                  path="/historial/nuevaHistoriaClinica"
                  element={<HistoriaClinica />}
                />
                <Route
                  path="/facturacion/CrearFactura"
                  element={<CrearFactura />}
                />
                <Route
                  path="/facturacion/CrearFacturaPublicoGeneral"
                  element={<CrearFacturaGeneral />}
                />
                <Route
                  path="/cortes/corteIndividual"
                  element={<CajaClosure />}
                />
                <Route
                  path="/cortes/panelAdmin"
                  element={<PanelCortesCaja />}
                />
                <Route
                  path="/pedido/tickets"
                  element={<PedidoTickets />}
                />
                <Route
                  path="/visita/historial"
                  element={<VisitasHistorial />}
                />
                <Route
                  path="/visita/historialDetalle"
                  element={<UltimaVisitaHistorial />}
                />
                <Route
                  path="/facturas/gestion"
                  element={<FacturasDashboard />}
                />
                <Route
                  path="/whatsapp/enviarPromocion"
                  element={<WhatsappPromociones />}
                />
              </>
            ) : userType === "1" ? (
              <>
                <Route path="/dashboard" element={<Clientes />} />
                <Route path="/dashboard/citas" element={<TodasCitas />} />
                <Route
                  path="/dashboard/crearPedido"
                  element={<CrearPedido />}
                />
                <Route
                  path="/dashboard/editarPedido"
                  element={<PedidoActualizar />}
                />
                <Route path="/dashboard/pedidos" element={<TodosPedidos />} />
                <Route path="/pedido/detalles" element={<PedidoDetalle />} />
                <Route
                  path="/facturacion/CrearFactura"
                  element={<CrearFactura />}
                />
                <Route
                  path="/cortes/corteIndividual"
                  element={<CajaClosure />}
                />
                <Route
                  path="/pedido/tickets"
                  element={<PedidoTickets />}
                />
                
                <Route
                  path="/visita/historial"
                  element={<VisitasHistorial />}
                />
                
                <Route
                  path="/visita/historialDetalle"
                  element={<UltimaVisitaHistorial />}
                />

                <Route
                  path="/facturas/gestion"
                  element={<FacturasDashboard />}
                />
              </>
            ) : userType === "2" ? (
              <>
                <Route path="/dashboard" element={<Clientes />} />
                <Route path="/dashboard/citas" element={<TodasCitas />} />
                <Route
                  path="/dashboard/crearPedido"
                  element={<CrearPedido />}
                />
                <Route
                  path="/dashboard/editarPedido"
                  element={<PedidoActualizar />}
                />
                <Route path="/dashboard/pedidos" element={<TodosPedidos />} />
                <Route path="/pedido/detalles" element={<PedidoDetalle />} />

                <Route
                  path="/historial/nuevaHistoriaClinica"
                  element={<HistoriaClinica />}
                />
                <Route
                  path="/facturacion/CrearFactura"
                  element={<CrearFactura />}
                />
                <Route
                  path="/cortes/corteIndividual"
                  element={<CajaClosure />}
                />
                <Route
                  path="/pedido/tickets"
                  element={<PedidoTickets />}
                />
                
                <Route
                  path="/historial/nuevaVisita"
                  element={<VisitaCrear />}
                />

                <Route
                  path="/visita/historial"
                  element={<VisitasHistorial />}
                />
                
                <Route
                  path="/visita/historialDetalle"
                  element={<UltimaVisitaHistorial />}
                />
                
                <Route
                  path="/facturas/gestion"
                  element={<FacturasDashboard />}
                />
              </>
            )  : userType === "3" ? (
              <>
                <Route path="/dashboard" element={<Default />} />
              </>
            ) : userType === "4" ? (
              <>
                <Route path="/dashboard" element={<Clientes />} />
                <Route path="/dashboard/citas" element={<TodasCitas />} />
                <Route
                  path="/historial/nuevaVisita"
                  element={<VisitaCrear />}
                />
                <Route
                  path="/historial/nuevaHistoriaClinica"
                  element={<HistoriaClinica />}
                />
                <Route
                  path="/visita/historial"
                  element={<VisitasHistorial />}
                />
                <Route
                  path="/visita/historialDetalle"
                  element={<UltimaVisitaHistorial />}
                />
              </>
            ) : null
          ) : (idPedidoCurrent && authenticated) ||
            (pedidoIdItem && authenticated) ? (
            <>
              <Route path="/dashboard" element={<PedidoEstatusUpdate />} />
            </>
          ) : null}
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
