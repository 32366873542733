export const templates = [
    {
      name: "Descuento Flash",
      header: "¡{{Nombre Cliente}}, Tenemos un Descuento Exclusivo para Ti Solo Hoy!",
      footer: "¡No pierdas esta oportunidad!",
      body: "¡Estamos ofreciendo un descuento especial del {{1}}% en {{2}}. Esta oferta termina hoy.\n ¡Aprovecha antes de que sea tarde!.\n Muestra este mensaje en sucursal para hacer válida tu promoción",
      placeholders: ["Porcentaje de Descuento", "Producto o Categoría"],
    },
    {
      name: "Promoción General",
      header: "¡Mira esta Promoción {{Nombre Cliente}}!",
      body: "Te invitamos a aprovechar nuestra promoción de {{1}} en {{2}}. No dejes pasar esta oportunidad, válida hasta el {{3}}. ¡Visítanos y disfruta de estas promociones increíbles!",
      footer: "¡Te esperamos!",
      placeholders: ["Porcentaje de Descuento o Promocion", "Producto o Categoría", "Fecha Límite"],
    },
    {
      name: "Promoción por Temporada",
      header: "¡Celebremos {{1}} con Grandes Descuentos!",
      body: "En esta temporada queremos ofrecerte {{2}} en {{3}}. Promoción válida hasta el {{4}}.",
      footer: "Disfruta la temporada con nosotros. 🎉",
      placeholders: ["Temporada", "Porcentaje de Descuento o Promocion", "Producto o Servicio", "Fecha Límite"],
    },
  ];
  