const Politicaprivacidad = () => {
  

  return (
    <section>
    <h1>Política de Privacidad</h1>

    <h2>1. Introducción</h2>
    <p>En <strong>Ópticas Pelayo</strong>, nos tomamos muy en serio la privacidad de nuestros clientes. Esta política de privacidad tiene como objetivo informarte sobre cómo recopilamos, usamos, almacenamos y protegemos tus datos personales, como tu número de teléfono y otra información personal que recopilamos a través de nuestro sitio web o durante la prestación de nuestros servicios.</p>

    <h2>2. Información que recopilamos</h2>
    <p>Recopilamos datos personales que nos proporcionas voluntariamente al registrarte, realizar compras o interactuar con nuestros servicios. Esto incluye, pero no se limita a:</p>
    <ul>
        <li>Nombre completo</li>
        <li>Teléfono</li>
        <li>Correo electrónico</li>
        <li>Dirección postal</li>
        <li>Otra información relevante para el servicio que ofrecemos</li>
    </ul>

    <h2>3. Uso de la información</h2>
    <p>Los datos personales que recopilamos son utilizados para los siguientes fines:</p>
    <ul>
        <li>Gestionar y procesar tus pedidos de productos y servicios.</li>
        <li>Enviarte comunicaciones sobre promociones, productos, servicios y novedades de Ópticas Pelayo.</li>
        <li>Mejorar la calidad de nuestro servicio y la experiencia de nuestros clientes.</li>
        <li>Cumplir con las obligaciones legales y fiscales que nos correspondan.</li>
    </ul>

    <h2>4. Protección de los datos personales</h2>
    <p>En <strong>Ópticas Pelayo</strong>, adoptamos medidas de seguridad técnicas y organizativas adecuadas para proteger tus datos personales contra accesos no autorizados, alteraciones o destrucción de los mismos. Sin embargo, debes tener en cuenta que, aunque nos esforzamos al máximo para proteger tu información, no podemos garantizar una seguridad absoluta en la transmisión de datos a través de Internet.</p>

    <h2>5. Compartir la información</h2>
    <p>En <strong>Ópticas Pelayo</strong>, no compartimos tus datos personales con terceros, excepto en los siguientes casos:</p>
    <ul>
        <li>Cuando sea necesario para cumplir con el procesamiento de tu pedido (por ejemplo, con proveedores de servicios de entrega).</li>
        <li>Cuando estemos obligados por ley o por regulaciones gubernamentales.</li>
    </ul>
    <p>Cualquier tercero con el que compartamos tus datos se compromete a protegerlos de acuerdo con nuestra política de privacidad.</p>

    <h2>6. Derechos de los usuarios</h2>
    <p>De acuerdo con las leyes de protección de datos, tienes derecho a:</p>
    <ul>
        <li>Acceder a los datos personales que tenemos sobre ti.</li>
        <li>Rectificar los datos que sean incorrectos o estén desactualizados.</li>
        <li>Solicitar la cancelación de tus datos personales, salvo que existan obligaciones legales que impidan hacerlo.</li>
        <li>Oponerte al tratamiento de tus datos en determinadas situaciones.</li>
    </ul>
    <p>Si deseas ejercer cualquiera de estos derechos, puedes contactarnos a través de los medios detallados al final de esta política.</p>

    <h2>7. Modificaciones a esta política</h2>
    <p>Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Cualquier cambio será publicado en esta página y, si es necesario, te notificaremos de los cambios realizados. Te recomendamos revisar esta política regularmente para estar informado sobre cómo estamos protegiendo tus datos.</p>

    <h2>8. Contacto</h2>
    <p>Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad o sobre el manejo de tus datos personales, puedes ponerte en contacto con nosotros a través de los siguientes medios:</p>
    <ul>
        <li>Correo electrónico: <a href="mailto:contacto@opticospelayo.com">contacto@opticospelayo.com</a></li>
        <li>Teléfono: [Número de teléfono]</li>
        <li>Dirección: [Dirección física de Ópticas Pelayo]</li>
    </ul>
</section>

  );
};

export default Politicaprivacidad;
