import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"; // Importación de SweetAlert2
import axios from "../../api/axiosConfig";
import { templates } from "./whatsappTemplates";
import Header from "../Header";
import "../../index.css";

const MessageTemplateEditor = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [customValues, setCustomValues] = useState({});
  const [finalMessage, setFinalMessage] = useState("");

  useEffect(() => {
    if (selectedTemplate) {
      let message = selectedTemplate.header;
      message += "\n" + selectedTemplate.body;
      message += "\n" + selectedTemplate.footer;

      selectedTemplate.placeholders.forEach((placeholder, index) => {
        const value = customValues[placeholder] || `{{${index + 1}}}`;
        message = message.replace(`{{${index + 1}}}`, value);
      });

      setFinalMessage(message);
    }
  }, [customValues, selectedTemplate]);

  const handleTemplateChange = (e) => {
    const selectedTemplateName = e.target.value;
    const template = templates.find(
      (template) => template.name === selectedTemplateName
    );
    setSelectedTemplate(template);
    setCustomValues({});
    setFinalMessage("");
  };

  const handleInputChange = (e, placeholder) => {
    setCustomValues({
      ...customValues,
      [placeholder]: e.target.value,
    });
  };

  const sendMessageToBackend = async () => {
    try {
        const response = await axios.post("/whatsapp/enviarPromocion", {
            templateName: selectedTemplate.name,
            customValues,
            finalMessage,
          });
          
          console.log("Respuesta del backend:", response.data);
          
          // Validamos el response
          if (response.data.results.some(result => !result.success)) {
            // Si algún mensaje falló
            Swal.fire({
              icon: "error",
              title: "Error en el envío",
              text: "Algunos mensajes no se enviaron correctamente. Por favor, contacte a soporte.",
            });
          } else {
            // Si todos los mensajes fueron exitosos
            Swal.fire({
              icon: "success",
              title: "Mensajes enviados",
              text: "Todos los mensajes fueron enviados correctamente.",
            });
          }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al enviar",
        text: "Hubo un problema al enviar el mensaje al backend.",
      });
      console.error("Error al enviar mensaje:", error);
    }
  };

  const handleSendMessage = () => {
    if (!selectedTemplate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor, selecciona una plantilla antes de enviar el mensaje.",
      });
      return;
    }

    const missingFields = selectedTemplate.placeholders.filter(
      (placeholder) =>
        !customValues[placeholder] || customValues[placeholder].trim() === ""
    );

    if (missingFields.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: `Por favor, completa los siguientes campos: ${missingFields.join(
          ", "
        )}`,
      });
      return;
    }

    sendMessageToBackend(); // Llamada a la función Axios
  };

  return (
    <div className="container mt-5">
      <Header />

      <div className="text-center mb-5">
        <h1 className="fw-bold">Editor de Plantillas de Mensajes</h1>
        <p className="text-muted">
          Personaliza tus mensajes de WhatsApp fácilmente.
        </p>
      </div>

      <div className="progress mb-4" style={{ height: "8px" }}>
        <div
          className={`progress-bar ${
            selectedTemplate ? "bg-success" : "bg-primary"
          }`}
          role="progressbar"
          style={{ width: selectedTemplate ? "100%" : "50%" }}
          aria-valuenow={selectedTemplate ? "100" : "50"}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="mb-4">
            <label htmlFor="templateSelect" className="form-label fw-semibold">
              Elige una plantilla:
            </label>
            <select
              id="templateSelect"
              className="form-select shadow-sm"
              onChange={handleTemplateChange}
            >
              <option value="">Selecciona una plantilla</option>
              {templates.map((template) => (
                <option key={template.name} value={template.name}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {selectedTemplate && (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h2 className="text-center fw-semibold">Personaliza la plantilla</h2>
            {selectedTemplate.placeholders.map((placeholder, index) => (
              <div key={index} className="mb-3">
                <label className="form-label fw-semibold">{placeholder}:</label>
                <input
                  type="text"
                  value={customValues[placeholder] || ""}
                  onChange={(e) => handleInputChange(e, placeholder)}
                  placeholder={`Escribe ${placeholder}`}
                  className="form-control shadow-sm"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {finalMessage && (
        <div className="row justify-content-center mt-5">
          <div className="col-md-8">
            <div className="card shadow-lg">
              <div className="card-header bg-primary text-white">
                <h5 className="mb-0">Vista Previa del Mensaje Generado</h5>
              </div>
              <div className="card-body">
                <pre className="bg-light p-3 rounded text-wrap">{finalMessage}</pre>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="text-center mt-4" style={{ marginBottom: "50px" }}>
        <button
          className="btn btn-success btn-lg shadow-sm"
          onClick={handleSendMessage}
          disabled={!finalMessage}
        >
          Enviar Mensaje
        </button>
      </div>
    </div>
  );
};

export default MessageTemplateEditor;
